<template>
    <div class="communicateLog">
        <div class="communicateLog-list">
            <div v-for="(item,index) in smartservice_conversation" :key="index" class="chatrecord-item" :class="item.is_self?'chatrecord-send':'chatrecord-receive'">
                <div class="chatrecord-item-avatar">
                    <a-avatar style="cursor:pointer" shape="square" :size="34" icon="user" :src="item.created_avatar" />
                </div>
                <div style="width:100%">
                    <div v-if="item.is_self" class="chatrecord-item-username">{{item.created_at}} {{item.created_by}}</div>
                    <div v-else class="chatrecord-item-username">{{item.created_by}} {{item.created_at}}</div>
                    <div class="chatrecord-item-content">
                        <div class="chatrecord-item-text">
                            <div>
                                <div>{{item.content}}</div>
                                <div v-if="item.image" style="overflow: hidden;">
                                    <img :preview="1" class="chatrecord-item-image" :src="item.image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="messages-send">
            <div>
                <a-textarea
                    v-model="mentionsValue"
                    placeholder="请输入..."
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                />
            </div>
            <div class="messages-send-icon">
                <div class="messages-send-iconL">
                    <a-upload
                        name="file" 
                        class="messages-send-upload"
                        :data="uploadParams"
                        :action="uploadUrl" 
                        :beforeUpload="beforeImageUpload"
                        :showUploadList="false"
                        listType="picture"
                        @change="changeFile">
                        <a-button>
                            <a-icon type="picture" /> 图片
                        </a-button>
                    </a-upload>
                </div>
                <div>
                    <a-dropdown-button :disabled="loading" @click="toSend()">
                        回复
                        <a-menu slot="overlay">
                            <a-menu-item @click="toSend(1)" key="1">回复并完成</a-menu-item>
                        </a-menu>
                    </a-dropdown-button>
                </div>
            </div>
            <div class="messages-send-img">
                <div class="messages-send-imgBox" v-for="(item,index) in fileList" :key="index">
                    <img :preview="index" :src="`http://i01.wedots.cn/${item.response.key}`">
                    <div @click.stop="delUpload(index,'fileList')" class="messages-send-close">
                        <a-icon type="close" />
                    </div>
                </div>
                <div v-if="upLoadLoading" class="messages-send-loading">
                    <vue-element-loading :active="upLoadLoading" color="#00cca2" spinner="spinner"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import url from '@/utils/URL'
    import createFilePath from '@/utils/tools'
    export default {
        name:'communicateLog',
        inject: ['parent'],
        props:{
            item:Object,
            smartservice_id:String,
            smartservice_conversation:Array
        },
        data() {
            return {
                mentionsValue: '',
                uploadUrl: url.uploadQiNiu, 
                uploadParams: {},
                fileList: [],
                loading:false,
                upLoadLoading:false
            }
        },
        mounted () {
            window.addEventListener("paste",this.listenPaste,true)
        },
        methods: {
            async toSend(num){
                let params = {}
                if(!this.mentionsValue && this.fileList.length==0){
                    this.$message.warning('请输入沟通内容!!')
                    return false
                }
                this.loading = true
                params.comment_content = this.mentionsValue
                if (this.fileList.length) {
                    let arr = []
                    for (let file of this.fileList) {
                        if (file.response.key) {
                            arr.push(file.response.key)
                        }
                    }
                    params.comment_image = arr[0]
                }
                if(num){
                    params.is_finish = 1
                }
                params.smartservice_id = this.smartservice_id
                params.comment_entity = this.item.comment_entity
                await this.$store.dispatch('enterpriseWorkOrderAddReplyAction', { data: params })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.mentionsValue = ''
                    this.fileList = []
                    this.parent.getDetail()
                    this.loading = false
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.loading = false
                })
            },
            async beforeImageUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeFile({ file, fileList }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList = [file]
                    this.upLoadLoading = false
                }
            },
            delUpload(index){
                this.fileList.splice(index,1)
            },
            async listenPaste(event){
                var data = event.clipboardData||window.clipboardData
                let blob = data.items[0].getAsFile()
                if(blob){
                    this.upLoadLoading = true
                    let name = createFilePath(blob.name)
                    let res = await this.$store.dispatch('imageTokenAction', {})
                    let formData = new FormData()
                    formData.append('key', name)
                    formData.append('token', res.data.uptoken)
                    formData.append("file", blob)
                    await this.$store.dispatch('uploadQiNiuAction', formData)
                    .then(result=>{
                        let fileObj = blob
                        fileObj.originFileObj = blob
                        fileObj.response = result
                        fileObj.uid = '-1'
                        fileObj.status = 'done'
                        this.fileList = [fileObj]
                        this.upLoadLoading = false
                    })
                }
            }
        },
        destroyed(){
            window.removeEventListener("paste", this.listenPaste,true)
        }
    }
</script>

<style lang="scss" scoped>
    .communicateLog{
        .messages-send{
            width: 100%;
            // margin: 0 auto;
            background: #fff;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e8e8e8;
            textarea{
                resize: none;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
            }
            &-iconL{
                display: flex;
                align-items: center;
                .ant-btn{
                    border: none;
                    box-shadow:none;
                    padding: 0px;
                }
            }
            &-upload{
                margin-right: 15px;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                }
            }
            &-imgBox{
                margin-right: 5px;
                margin-bottom: 5px;
                position: relative;
            }
            &-close{
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250,250,250,.5);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
            }
            &-loading{
                width: 60px;
                height: 60px;
            }

        }
        .chatrecord-item{
            width: 100%;
            display: flex;
            margin: 10px 0;
            &-username{
                margin-bottom: 5px;
            }
            &-content{
                width: 100%;
                align-items: flex-end;
            }
            &-text{
                max-width: 80%;
                padding: 8px;
                white-space: pre-wrap;
                word-break: break-word;
                border-radius: 2px;
                cursor: auto;
            }
            &-otherText{
                max-width: 80%;
                white-space: pre-wrap;
                word-break: break-word;
                border-radius: 2px;
                cursor: pointer;
            }
            &-image{
                max-height: 260px;
                border-radius: 2px;
            }
        }
        .chatrecord-receive{
            padding-left: 15px;
            .chatrecord-item-avatar{
                margin-right: 10px;
            }
            .chatrecord-item-content{
                display: flex;
            }
            .chatrecord-item-text{
                background: #f0f2f5;
                color: #222;
            }
            .chatrecord-item-otherText{
                color: #222;
            }
        }
        .chatrecord-send{
            padding-right: 15px;
            flex-direction: row-reverse;
            .chatrecord-item-avatar{
                margin-left: 10px;
            }
            .chatrecord-item-username{
                display: flex;
                flex-direction: row-reverse;
            }
            .chatrecord-item-content{
                display: flex;
                flex-direction: row-reverse;
            }
            .chatrecord-item-text{
                background: #1992f9;
                color: #fff;
            }
            .chatrecord-item-otherText{
                color: #222;
            }
        }
    }
</style>