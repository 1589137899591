<template>
    <div class="zl-list">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>通用设置</a-breadcrumb-item>
            <a-breadcrumb-item>工单系统</a-breadcrumb-item>
            <a-breadcrumb-item>工单详情</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box smartservice-detail">
            <div class="title flts-title">
                <h3>工单详情</h3>
                <div>
                    <a-button size="small" type="primary" @click="refresh">刷新</a-button>
                    <a-button size="small" style="margin-left:10px" @click="handleCancel">返回</a-button>
                </div>
            </div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-steps :current="detailInfo.smartservice_step">
                <a-step title="待处理"/>
                <a-step title="正在处理"/>
                <a-step title="待您反馈"/>
                <a-step title="工单关闭"/>
            </a-steps>
            <div class="smartservice-detail-info">
                <a-descriptions class="smartservice-descriptions" layout="vertical" size="small" :column="4">
                    <a-descriptions-item label="问题标题">
                    {{detailInfo.smartservice_subject}}
                    </a-descriptions-item>
                    <a-descriptions-item label="工单编号">
                    {{detailInfo.smartservice_id}}
                    </a-descriptions-item>
                    <a-descriptions-item label="优先级">
                    {{detailInfo.smartservice_level_name}}
                    </a-descriptions-item>
                    <a-descriptions-item label="工单类型">
                    {{detailInfo.smartservice_category}}
                    </a-descriptions-item>
                    <a-descriptions-item label="提交账号">
                    {{detailInfo.created_by}}
                    </a-descriptions-item>
                    <a-descriptions-item label="提交时间">
                    {{detailInfo.created_at}}
                    </a-descriptions-item>
                    <a-descriptions-item label="处理工时">
                    {{detailInfo.smartservice_costs}}
                    </a-descriptions-item>
                    <a-descriptions-item label="工单状态">
                    {{detailInfo.smartservice_status_name}}
                    </a-descriptions-item>
                    <a-descriptions-item label="关单账户">
                    {{detailInfo.closed_by?detailInfo.closed_by:'--'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="关单时间">
                    {{detailInfo.closed_at?detailInfo.closed_at:'--'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="完成账户">
                    {{detailInfo.finish_by?detailInfo.finish_by:'--'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="完成时间">
                    {{detailInfo.finish_at?detailInfo.finish_at:'--'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="工作人员">
                    <div>
                        <div v-for="(item,index) in detailInfo.smartservice_tasks" :key="index">
                            {{item.work_name}}/{{item.work_costs}}/{{item.work_expect_finish_time}}
                        </div>
                    </div>
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <a-button type="dashed" icon="download" @click="download" v-if="detailInfo.smartservice_attachment">
                {{detailInfo.smartservice_attachment.name}}
            </a-button>
            <a-tabs default-active-key="3">
                
                <a-tab-pane key="3" tab="工单内容">
                    <contents :smartservice_content="smartservice_content" />
                </a-tab-pane>

                <a-tab-pane key="1" :tab="`沟通记录 (${detailInfo.smartservice_conversation_count?detailInfo.smartservice_conversation_count:0})`">
                    <communicateLog :smartservice_id="smartservice_id" :item="detailInfo" :smartservice_conversation="smartservice_conversation" />
                </a-tab-pane>

                
                
                <a-tab-pane key="2" :tab="`变更记录 (${detailInfo.smartservice_version_count?detailInfo.smartservice_version_count:0})`" force-render>
                    <changeLog ref="changeLog" :smartservice_id="smartservice_id" />
                </a-tab-pane>

            </a-tabs>
        </div>
    </div>
</template>

<script>
import changeLog from './changeLog'
    import contents from './contents'
    import communicateLog from './communicateLog'
    export default {
        name:'detailModal',
        data() {
            return {
                loading:false,
                detailInfo: {},
                smartservice_id:'0',
                smartservice_content:'',
                smartservice_conversation:[],
            }
        },
        provide() {
            return {
            parent: this
            }
        },
        components: {
            contents,
            changeLog,
            communicateLog
        },
        created () {
            this.smartservice_id = this.$route.query.id
            this.getDetail()
        },
        methods: {
            async getDetail(){
                this.loading = true
                await this.$store.dispatch('enterpriseWorkOrderDetailAction',{data:{smartservice_id:this.smartservice_id}})
                .then(res=>{
                    this.detailInfo = res.data
                    this.smartservice_conversation = res.data.smartservice_conversation
                    this.smartservice_content = this.replaceurl(res.data.smartservice_content)
                    this.loading = false
                })
            },
            download:function(){
                window.open(`${this.detailInfo.smartservice_attachment.path}`);
            },
            handleCancel() {
                this.$router.go(-1)
            },
            refresh(){
                this.getDetail()
                this.$refs.changeLog.getList()
            },
            // 给图片加上预览属性
            replaceurl(detail) {
                var content = detail.replace(/<img[^>]*>/gi, function(match) {
                    let matchStr = match.slice(0, 4) + ` preview=${0}` + match.slice(4)
                    return matchStr
                })
                return content
            }
        },
    }
</script>

<style lang="scss" scoped>
    .smartservice-detail{
        &-info{
            margin: 30px 0;
            background: #f8f8f8;
            padding: 15px;
            border-radius: 2px;
            .ant-descriptions-item{
                padding: 5px 0;
                padding-bottom: 0px;
            }
        }
    }
</style>

<style lang="scss">
    .smartservice-detail{
        width: 70%;
        margin: 0 auto;
        &-info{
            .ant-descriptions-item{
                padding: 5px 0;
                padding-bottom: 0px;
            }
        }
        .smartservice-descriptions{
            .ant-descriptions-item-label{
                color: rgba(69, 90, 100, 0.6);
            }
            .ant-descriptions-item-content{
                color: #323233;
            }
        }
    }
</style>