<template>
    <div class="smartservice_contents">
        <div v-html="smartservice_content"></div>
    </div>
</template>

<script>
    export default {
        name:'contents',
        data() {
            return {}
        },
        props:{
            smartservice_content:String
        },
    }
</script>

<style lang="scss">
.smartservice_contents{
    width: 100%;
    img{
        max-width: 100%;
    }
}
</style>